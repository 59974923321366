import React from 'react';
import reorder from '../../../helpers/reorder';
import { Add as AddIcon } from '@material-ui/icons';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { GroupDto } from '../../../api/coach.generated';
import { PathsApi } from '../../../api/coachApi';
import {
  useParams,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation
} from 'react-router-dom';
import Skill from './Skill';
import { Box, Fab, makeStyles, Tooltip } from '@material-ui/core';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DropResult
} from 'react-beautiful-dnd';
import usePathContext from '../usePathContext';
import LoadingSkeleton from '../../../components/LoadingSkeleton';

// Groups
// - Courses

const useStyles = makeStyles((theme) => ({
  skill: {
    display: 'flex'
  }
}));

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

const List = ({ canEdit }: { canEdit: boolean }) => {
  const classes = useStyles();
  const { pathSlug } = useParams<{ pathSlug: string }>();
  const { pathname } = useLocation();
  const { dragEndCallbacks } = usePathContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const [skills, setSkills] = React.useState<GroupDto[]>([]);

  React.useEffect(() => {
    if (!pathSlug) {
      setSkills([]);
      return;
    }

    const fetchSkills = async () => {
      setIsLoading(true);
      const skillsRes = await PathsApi.groupedSkillsForPath(pathSlug);
      skillsRes && setSkills(skillsRes);
      setIsLoading(false);
    };
    pathSlug && fetchSkills();
  }, [pathname, pathSlug]);

  const updateRanking = React.useCallback(
    (result: DropResult) => {
      if (!result || result.type !== 'SKILLS') return;
      if (!result.destination) return;

      const reorderedSkills = reorder(
        skills,
        result.source.index,
        result.destination.index
      );

      const rankings: { [key: string]: number } = {};
      reorderedSkills.forEach((val, idx) => {
        rankings[val.id] = idx;
      });

      setSkills(reorderedSkills);
      PathsApi.updateSkillRankings({
        pathSlug: pathSlug,
        skillRankings: rankings
      });
    },
    [pathSlug, skills]
  );

  React.useEffect(() => {
    const cb = dragEndCallbacks.current;
    cb['skills'] = updateRanking;
    return () => {
      delete cb['skills'];
    };
  }, [dragEndCallbacks, updateRanking]);

  return (
    <div style={{ margin: '40px 0 0 20px', display: 'flex' }}>
      {isLoading && <LoadingSkeleton variant="list" />}
      {!isLoading && (
        <>
          <Droppable droppableId="droppable" type="SKILLS">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {skills.length > 0 &&
                  skills.map((skill, idx) => (
                    <Draggable
                      key={skill.id}
                      draggableId={`drag-${skill.id}`}
                      index={idx}
                      isDragDisabled={!canEdit}
                    >
                      {(
                        provided: DraggableProvided,
                        snapshot: DraggableStateSnapshot
                      ) => (
                        <div
                          className={classes.skill}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Box mt={1} {...provided.dragHandleProps}>
                            <DragHandleIcon />
                          </Box>
                          <Skill skill={skill} canEdit={canEdit} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {canEdit && (
            <Box ml={10}>
              <Tooltip title="Add course to current path">
                <Fab
                  color="primary"
                  aria-label="add"
                  size="small"
                  component={Link}
                  to={`/admin/paths/${pathSlug}/add`}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default List;
