import React from 'react';
import moment from 'moment';
import { green, grey } from '@material-ui/core/colors';
import { styled, makeStyles, Typography, useTheme, Box } from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  PanoramaFishEye as PanoramaFishEyeIcon,
  TrackChanges as TrackChangesIcon
} from '@material-ui/icons';
import { CourseDto, Status } from '../../../api/coach.generated';
import CourseDetails from '../../../components/CourseDetails';

//TODO: remove old
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    cursor: 'pointer'
  },
  iconContainer: {
    flexShrink: 0, // Fix IE 11 issue
    display: 'flex',
    paddingRight: 8
  },
  labelContainer: {
    width: '100%'
  },
  text: {
    fill: theme.palette.primary.contrastText,
    fontSize: theme.typography.caption.fontSize,
    fontFamily: theme.typography.fontFamily
  },
  content: {
    marginTop: 8,
    borderLeft: '1px solid #bdbdbd',
    marginLeft: 12,
    paddingLeft: 20,
    paddingRight: 8
  }
}));

const Course = ({
  course,
  actions,
  hideSkillAndRank
}: {
  course: CourseDto;
  actions?: React.ReactNode;
  hideSkillAndRank?: boolean;
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const DoneIcon = styled(CheckCircleIcon)({ color: green[500] });
  const ActiveIcon = styled(TrackChangesIcon)({ color: theme.palette.primary.main });
  const NotDoneIcon = styled(PanoramaFishEyeIcon)({ color: grey[500] });

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setOpen((open) => !open);
  };

  return (
    <Box>
      <div className={classes.root} onClick={onClick}>
        <span className={classes.iconContainer}>
          {course.status === Status.Done ? (
            <DoneIcon />
          ) : course.status === Status.InProgress ? (
            <ActiveIcon />
          ) : (
            <NotDoneIcon />
          )}
        </span>
        <span className={classes.labelContainer}>
          <Typography variant="body2" component="span">
            <CourseDetails course={course} hideSkillAndRank={hideSkillAndRank} />
          </Typography>
        </span>
      </div>
      <div className={classes.content}>
        {open && (
          <>
            {printTimeStamp(course)}
            <Typography>
              <a href={course.url} target="_blank" rel="noopener noreferrer">
                {course.url}
              </a>
            </Typography>
            {actions}
          </>
        )}
      </div>
    </Box>
  );
};

const printTimeStamp = (course: CourseDto) => {
  if (course.completedOn)
    return (
      <Typography variant="caption">
        Completed on: {moment(course.completedOn).format('l')}
      </Typography>
    );

  if (course.startedOn)
    return (
      <Typography variant="caption">
        Started on: {moment(course.startedOn).format('l')}
      </Typography>
    );

  return <Typography variant="caption">Not Started</Typography>;
};

export default Course;
