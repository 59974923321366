import React from 'react';
import clsx from 'clsx';
import { useParams, Route, useHistory } from 'react-router-dom';
import { makeStyles, styled, Theme, createStyles } from '@material-ui/core/styles';
import { Box, Toolbar, Tooltip, Typography } from '@material-ui/core';
import {
  FormatListBulleted as FormatListBulletedIcon,
  VpnKey as KeyIcon
} from '@material-ui/icons';
import AddPath from './AddPath';
import AddCourse from '../../../components/Course/Add';
import Skills from './Skills';
import useDashboardContext from '../useDashboardContext';
import LoadingSkeleton from '../../../components/LoadingSkeleton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingLeft: theme.spacing(2)
    },
    pathActive: {
      backgroundColor: '#e8e8e8'
    },
    completedPct: { marginLeft: 5 }
  })
);

const Path = styled('div')({
  display: 'flex',
  whiteSpace: 'nowrap',
  alignItems: 'center',
  padding: '6px 9px 8px 9px',
  '& svg': { position: 'relative', top: 2, marginRight: 5 },
  '&:hover': {
    backgroundColor: '#e8e8e8',
    cursor: 'pointer'
  }
});

const Pathways = () => {
  const classes = useStyles();
  const history = useHistory();
  const { paths, pathsLoading } = useDashboardContext();
  const { pathSlug } = useParams<{ pathSlug: string }>();

  const changePath = (pathSlug: string) => {
    history.push(`/dashboard/${pathSlug}`);
  };

  return (
    <>
      <Toolbar>
        <Typography variant="h6" id="tableTitle" style={{ flex: '1 1 100%' }}>
          My Pathways
        </Typography>
      </Toolbar>
      {!pathsLoading && paths && !paths.length && (
        <Typography variant="h3" style={{ padding: 40 }}>
          User has no paths
        </Typography>
      )}
      <>
        <Box className={classes.container}>
          {paths.map((p) => (
            <Path
              key={p.pathId}
              onClick={(_) => changePath(p.pathSlug)}
              className={clsx({
                [classes.pathActive]: p.pathSlug === pathSlug
              })}
            >
              {p.isPrivate ? (
                <Tooltip title="Private">
                  <KeyIcon />
                </Tooltip>
              ) : (
                <Tooltip title="Public">
                  <FormatListBulletedIcon />
                </Tooltip>
              )}
              <b>{p.pathName}</b>
              <span className={classes.completedPct}>{p.completedPct}%</span>
            </Path>
          ))}
        </Box>
        {!pathSlug && !!paths?.length && <Box p={3}>Select a path</Box>}
        <Box ml={2}>
          {!pathsLoading && <Skills />}
          {pathsLoading && 
            <Box m={3} pb={2}>
              <LoadingSkeleton variant="list" />
            </Box>
          }
        </Box>
      </>

      <Route path="/dashboard/paths/add" component={AddPath} />
      <Route path="/dashboard/paths/:pathSlug/add" component={AddCourse} />
    </>
  );
};

export default Pathways;
