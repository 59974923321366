import React from 'react';
import { toast } from '../components/Toaster';
import { ApiResponse } from './coach.generated';

export const httpMiddleware = {
  fetch: async (url: string, options: RequestInit): Promise<Response> => {
    options.credentials = 'include';
    let response = null;
    try {
      response = await fetch(url, options);
    } catch (error) {}
    if (response!.status === 401) redirectToLogin();
    else if (!response || !response.ok) {
      const body = await response!.text();
      let responseObj: ApiResponse | null = null;
      if (body.startsWith('{')) {
        responseObj = JSON.parse(body) as ApiResponse;
      }

      toast({
        variant: 'error',
        message: (
          <div>
            {responseObj?.genericError || 'Oops, something went wrong'} <br />
            <div>
              <a
                style={{ color: '#fff', fontWeight: 'bold' }}
                href="https://bondora.slack.com/app_redirect?channel=report-coach-errors"
              >
                Click here to report
              </a>
            </div>
          </div>
        )
      });
      // Override status code to prevent Nswag from raising an exception
      return body.startsWith('{') // JSON format?
        ? new Response(body, { status: 200 })
        : new Response(null, { status: 204 });
    }

    return response!;
  }
};

const redirectToLogin = () => {
  const loginPath = '/login';
  const encodedReturnUri = encodeURIComponent(
    window.location.pathname + window.location.search
  );
  window.location.href = `${loginPath}?ReturnUrl=${encodedReturnUri}`;
};
