import React from 'react';
import Form from './Form';
import { useHistory, useParams } from 'react-router-dom';
import { UsersApi, PathsApi, RolesApi } from '../../api/coachApi';
import {
  Profile,
  UpdateUserRequest,
  Path,
  ApiResponse,
  SelectListItem
} from '../../api/coach.generated';
import { toaster } from '../../components/Toaster';
import { CoachDialogWithTitle } from '../../components/CoachDialog';

const Edit = () => {
  const history = useHistory();
  let { userId } = useParams<{ userId: string }>();
  const [profile, setProfile] = React.useState<Profile | null>(null);
  const [paths, setPaths] = React.useState<Path[] | null>(null);
  const [usersList, setUsersList] = React.useState<SelectListItem[] | null>(null);
  const [roles, setRoles] = React.useState<string[]>();

  React.useEffect(() => {
    const fetchData = async () => {
      if (!userId) return;

      const [userRes, pathsRes, usersListRes, rolesRes] = await Promise.all([
        UsersApi.getById(userId),
        PathsApi.get(),
        UsersApi.usersSelectList(),
        RolesApi.get()
      ]);
      setProfile(userRes);
      setPaths(pathsRes);
      setUsersList(usersListRes);
      setRoles(rolesRes?.map((r) => r.name!));
    };
    fetchData();
  }, [userId]);

  const success = () => {
    history.push('/admin/users');
    toaster.success('User updated');
  };

  const close = () => {
    history.push('/admin/users');
  };

  const loading: boolean = !profile || !paths;

  const endpoint = (model: UpdateUserRequest): Promise<ApiResponse | null> =>
    UsersApi.put(profile!.id, { ...model });

  return (
    <CoachDialogWithTitle title="Update user" open onClose={close} loading={loading}>
      {profile && usersList && paths && roles && (
        <Form
          profile={profile}
          usersList={usersList}
          allPaths={paths}
          allRoles={roles}
          onSuccess={success}
          endpoint={endpoint}
        />
      )}
    </CoachDialogWithTitle>
  );
};

export default Edit;
