import React from 'react';
import { makeStyles, IconButton, CircularProgress, Tooltip } from '@material-ui/core';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons';
import { toast } from '../../components/Toaster';
import { UsersApi } from '../../api/coachApi';
import { ApiResponse } from '../../api/coach.generated';

const useStyles = makeStyles((theme) => ({
  root: { position: 'relative', display: 'inline-block' },
  progress: { position: 'absolute', top: 9, left: 9 }
}));

const Delete = ({
  profileId,
  disabled,
  onDeleted
}: {
  profileId: string;
  disabled: boolean;
  onDeleted: (profileId: string) => void;
}) => {
  const classes = useStyles();
  const [deleteInProgress, setDeleteInProgress] = React.useState(false);

  const handleDelete = async (id: string) => {
    if (!window.confirm('Are you sure?')) return false;

    let response: ApiResponse;
    setDeleteInProgress(true);
    response = (await UsersApi.delete(id))!;
    setDeleteInProgress(false);
    if (response) {
      toast({ message: 'User deleted', variant: 'success' });
      onDeleted(id);
    } else toast({ message: 'Oops', variant: 'error' });
  };

  return (
    <div className={classes.root}>
      <Tooltip title={disabled ? 'No rights' : 'Delete'} aria-label="add">
        <span>
          <IconButton
            disabled={disabled || deleteInProgress}
            onClick={() => handleDelete(profileId)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </span>
      </Tooltip>
      {deleteInProgress && <CircularProgress size={30} className={classes.progress} />}
    </div>
  );
};

export default Delete;
