import React from 'react';
import Skills, { SkillOptionType } from '../../../components/Skills';
import { makeStyles } from '@material-ui/core/styles';
import { UpdateCourseRequest, ApiResponse } from '../../../api/coach.generated';
import { useFormHandler, FormHandler } from '../../../helpers/FormHandler';
import { DialogContent } from '@material-ui/core';
import { CoachDialogActions } from '../../../components/CoachDialog';

const useStyles = makeStyles((theme) => ({
  form: {
    paddingLeft: 30,
    paddingRight: 30,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  textField: {
    marginTop: theme.spacing(2)
  }
}));

const EditCourseForm = ({
  course,
  endpoint,
  onSuccess
}: {
  course?: UpdateCourseRequest;
  endpoint(model: UpdateCourseRequest): Promise<ApiResponse | null>;
  onSuccess(): void;
}) => {
  const classes = useStyles();

  const formHandler: FormHandler<UpdateCourseRequest> = useFormHandler({
    model: {
      ...(course as UpdateCourseRequest)
    },
    endpoint: () => endpoint(formHandler.state.model),
    onSuccess: onSuccess
  });

  return (
    <>
      <DialogContent>
        <form className={classes.form} autoComplete="off">
          {formHandler.GenericError()}
          {formHandler.FormField({ propName: 'name', required: true })}
          {formHandler.FormField({ propName: 'url', required: true })}
          <Skills
            value={formHandler.state.model.skill ?? null}
            setValue={(skill: SkillOptionType) => formHandler.updateProp('skill', skill)}
            error={formHandler.propError('skill')}
          />
          {formHandler.FormField({ propName: 'hours' })}
          {formHandler.FormField({ propName: 'minutes' })}
          {formHandler.FormField({ propName: 'seconds' })}
        </form>
      </DialogContent>
      <CoachDialogActions
        onSubmit={formHandler.execute}
        actionInProgress={formHandler.state.inProgress}
      />
    </>
  );
};

export default EditCourseForm;
