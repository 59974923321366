import * as React from 'react';
import { UserStatsByPath } from '../../api/coach.generated';
import { styled } from '@material-ui/core';

const StatsContainer = styled('div')({
  marginLeft: '1rem',
  padding: '1rem',
  // borderRadius: '.5rem',
  '&:first-of-type': { margin: 0 },
  '&:hover': { backgroundColor: '#e7e7e7', cursor: 'pointer' }
});

const UserStats = ({
  stats,
  onClick
}: {
  stats: UserStatsByPath;
  onClick(event: React.MouseEvent): void;
}) => {
  return (
    <StatsContainer key={stats.pathId} onClick={onClick} title="View path">
      <b>{stats.pathName}</b>
      <br />
      Courses:
      <b>{` ${stats.nrOfUserCoursesFinished}/${stats.totalNrOfCourses} @ 
      ${stats.countPct}%`}</b>
      <br />
      Duration: <b>{stats.durationPct}%</b>
      {/* <br />
    Montly avg {good('18h (113%)')} (opt 16h)
    <br />
    Sep {good('20h')} • Nov {bad('7h')} • <b>Dec 7h</b> */}
    </StatsContainer>
  );
};

export default UserStats;
