import React from 'react';
import Form from './Form';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useHistory, useParams } from 'react-router-dom';
import { CoursesApi } from '../../../api/coachApi';
import { ApiResponse, Course, UpdateCourseRequest } from '../../../api/coach.generated';
import { toaster } from '../../../components/Toaster';
import { CoachDialogWithTitle } from '../../../components/CoachDialog';

const Alert = (props: AlertProps) => <MuiAlert variant="filled" {...props} />;

const EditCourse = () => {
  const history = useHistory();
  const { pathSlug, courseId } = useParams<{ pathSlug: string; courseId: string }>();
  const [courseRequest, setCourseRequest] = React.useState<UpdateCourseRequest>();
  const [course, setCourse] = React.useState<Course>();

  React.useEffect(() => {
    const fetchData = async () => {
      if (!courseId) return;

      const course = await CoursesApi.getById(courseId);
      if (course) {
        setCourse(course);
        setCourseRequest({
          name: course.name,
          url: course.url,
          skill: course.skill,
          seconds: course.seconds,
          minutes: course.minutes,
          hours: course.hours
        });
      }
    };
    fetchData();
  }, [courseId]);

  const success = () => {
    history.push(`/admin/paths/${pathSlug}`);
    toaster.success('Course updated');
  };

  const close = () => {
    history.push(`/admin/paths/${pathSlug}`);
  };

  const loading: boolean = !courseRequest;

  const endpoint = (model: UpdateCourseRequest): Promise<ApiResponse | null> =>
    CoursesApi.put(course!.id, { ...model });

  return (
    <CoachDialogWithTitle title="Update course" open onClose={close} loading={loading}>
      <Alert severity="warning" style={{ margin: 10 }}>
        Editing this course will update it in every path it's added to.
      </Alert>
      {courseRequest && (
        <Form course={courseRequest} onSuccess={success} endpoint={endpoint} />
      )}
    </CoachDialogWithTitle>
  );
};

export default EditCourse;
