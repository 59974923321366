import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AddPathRequest, ApiResponse } from '../../../api/coach.generated';
import { useFormHandler, FormHandler } from '../../../helpers/FormHandler';
import { DialogContent } from '@material-ui/core';
import { CoachDialogActions } from '../../../components/CoachDialog';

const useStyles = makeStyles((theme) => ({
  form: {
    paddingLeft: 30,
    paddingRight: 30,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  textField: {
    marginTop: theme.spacing(2)
  }
}));

const PathForm = ({
  course,
  endpoint,
  onSuccess
}: {
  course?: AddPathRequest;
  endpoint(model: AddPathRequest): Promise<ApiResponse | null>;
  onSuccess(): void;
}) => {
  const classes = useStyles();

  const formHandler: FormHandler<AddPathRequest> = useFormHandler({
    model: {
      ...(course as AddPathRequest)
    },
    endpoint: () => endpoint(formHandler.state.model),
    onSuccess: onSuccess
  });

  return (
    <>
      <DialogContent>
        <form className={classes.form} autoComplete="off">
          {formHandler.GenericError()}
          {formHandler.FormField({ propName: 'name', required: true })}
        </form>
      </DialogContent>
      <CoachDialogActions
        onSubmit={formHandler.execute}
        actionInProgress={formHandler.state.inProgress}
      />
    </>
  );
};

export default PathForm;
