import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  UpdateUserRequest,
  Path,
  Profile,
  ApiResponse,
  SelectListItem
} from '../../api/coach.generated';
import { useFormHandler, FormHandler } from '../../helpers/FormHandler';
import { DialogContent } from '@material-ui/core';
import { CoachDialogActions } from '../../components/CoachDialog';

const useStyles = makeStyles((theme) => ({
  form: {
    paddingLeft: 30,
    paddingRight: 30,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  groupHeading: {
    margin: '15px 0 -15px 0'
  }
}));

const rolesThatCanBeChanged = ['Admin'];

const Form = ({
  profile,
  allPaths,
  allRoles,
  usersList,
  endpoint,
  onSuccess
}: {
  profile?: Profile;
  allPaths: Path[];
  allRoles: string[];
  usersList: SelectListItem[];
  endpoint(model: UpdateUserRequest): Promise<ApiResponse | null>;
  onSuccess(): void;
}) => {
  const classes = useStyles();

  const formHandler: FormHandler<UpdateUserRequest> = useFormHandler({
    model: {
      ...(profile as Profile),
      paths: allPaths?.map((path) => ({
        id: path.id,
        label: path.name,
        selected: !!profile?.paths[path.id]
      })),
      roles: allRoles
        ?.filter((role) => rolesThatCanBeChanged.includes(role))
        .map((role) => ({
          id: role,
          label: role,
          selected: !!profile?.roles.includes(role)
        }))
    },
    endpoint: () => endpoint(formHandler.state.model),
    onSuccess: onSuccess
  });

  return (
    <>
      <DialogContent>
        <form className={classes.form} autoComplete="off">
          {formHandler.GenericError()}
          {formHandler.FormField({ propName: 'firstName', required: true })}
          {formHandler.FormField({ propName: 'lastName', required: true })}
          {formHandler.FormField({ propName: 'email', required: true })}
          {formHandler.FormField({
            propName: 'reportsToId',
            label: 'Reports to',
            type: 'dropdown',
            list: usersList
          })}
          {formHandler.FormField({ propName: 'position' })}
          {formHandler.FormField({ propName: 'team' })}
          {formHandler.FormField({
            propName: 'paths',
            label: 'Paths',
            type: 'checklist'
          })}
          {formHandler.FormField({
            propName: 'roles',
            label: 'Roles',
            type: 'checklist'
          })}
        </form>
      </DialogContent>
      <CoachDialogActions
        onSubmit={formHandler.execute}
        actionInProgress={formHandler.state.inProgress}
      />
    </>
  );
};

export default Form;
