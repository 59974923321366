import React from 'react';
import PathForm from './PathForm';
import { useHistory } from 'react-router-dom';
import { DashboardApi } from '../../../api/coachApi';
import {
  ApiResponse,
  UpdateCourseRequest,
  AddPathRequest
} from '../../../api/coach.generated';
import { toaster } from '../../../components/Toaster';
import { CoachDialogWithTitle } from '../../../components/CoachDialog';

const AddPath = () => {
  const history = useHistory();

  const success = () => {
    history.push('/dashboard');
    toaster.success('Private path added');
  };

  const close = () => {
    history.push('/dashboard');
  };

  const endpoint = (model: AddPathRequest): Promise<ApiResponse | null> =>
    DashboardApi.addPrivatePath(model);

  return (
    <CoachDialogWithTitle title="Add a private path" open onClose={close} loading={false}>
      {
        <PathForm
          course={{} as UpdateCourseRequest}
          onSuccess={success}
          endpoint={endpoint}
        />
      }
    </CoachDialogWithTitle>
  );
};

export default AddPath;
