import React from 'react';
import { CoachDialogWithTitle } from '../../components/CoachDialog';
import { toast } from '../../components/Toaster';
import {
  Box,
  DialogContent,
  TextField,
  DialogActions,
  makeStyles,
  Button
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import CircularLoadingButton from '../../components/CircularLoadingButton';
import { CourseReviewsApi } from '../../api/coachApi';
import { CourseReview } from '../../api/coach.generated';
import useAppContext from '../../hooks/useAppContext';

const useStyles = makeStyles((theme) => ({
  actions: {
    marginTop: theme.spacing(1)
  }
}));

const Reviews = ({
  open,
  editorOpen = false,
  courseId,
  onClose
}: {
  open: boolean;
  editorOpen: boolean;
  courseId: string;
  onClose(): void;
}) => {
  const classes = useStyles();
  const { notifyOnCourseUpdate } = useAppContext();
  const [edit, setEdit] = React.useState(editorOpen);
  const [courseName, setCourseName] = React.useState<string>();
  const [rating, setRating] = React.useState<number | null>(null);
  const [reviews, setReviews] = React.useState<CourseReview[]>();
  const [review, setReview] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);

  const fetchReviews = React.useCallback(async () => {
    setLoading(true);
    const res = await CourseReviewsApi.reviews(courseId);
    if (!res) return;
    setRating(res.review?.rating ?? 0);
    setReview(res.review?.review ?? '');
    setCourseName(res.courseName);
    setReviews(res.reviews);
    setLoading(false);
  }, [courseId]);

  React.useEffect(() => {
    fetchReviews();
  }, [fetchReviews]);

  const saveReview = async () => {
    if (!rating) {
      alert('Please rate the course');
      return;
    }
    setLoading(true);
    try {
      await CourseReviewsApi.addReview({ rating, review }, courseId);
      notifyOnCourseUpdate(courseId);
    } catch (error) {
    } finally {
      fetchReviews();
      toast({ variant: 'success', message: 'Saved' });
      setLoading(false);
    }
  };

  const handleReviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReview(event.target.value);
  };

  return (
    <CoachDialogWithTitle
      title={`Reviews: ${courseName}`}
      open={open}
      onClose={onClose}
      loading={loading}
    >
      <DialogContent>
        {edit && (
          <>
            <Box textAlign="center">
              <Rating
                name="rating"
                value={rating ?? 0}
                onChange={(_, value) => setRating(value)}
              />
            </Box>
            <Box style={{ marginTop: 30 }}>
              <TextField
                label="Feedback"
                multiline
                rows={4}
                value={review}
                onChange={handleReviewChange}
                variant="outlined"
                style={{ width: '100%' }}
              />
            </Box>
            <DialogActions className={classes.actions}>
              <CircularLoadingButton onClick={saveReview} loading={loading} />
            </DialogActions>
          </>
        )}
        {!edit && (
          <Button onClick={() => setEdit((val) => !val)}>
            {review ? 'Update' : 'Add'} review
          </Button>
        )}

        <Box mb={3}>
          {!reviews?.length && <p>No reviews yet</p>}
          {reviews?.map((r) => (
            <Box mt={3} key={r.id}>
              <Box display="flex">
                <b>{r.user?.fullName}</b>
                <Rating
                  name="rating"
                  value={r.rating}
                  size="small"
                  style={{ marginLeft: 10 }}
                  disabled
                />
              </Box>
              <Box display="block" style={{ whiteSpace: 'pre-wrap' }}>
                {r.review}
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </CoachDialogWithTitle>
  );
};

export default Reviews;
