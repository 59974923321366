import * as React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green, grey, yellow } from '@material-ui/core/colors';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { FormControl, RadioGroup, FormControlLabel, Button } from '@material-ui/core';
import {
  PlaylistAdd as PlaylistAddIcon,
  DeleteOutline as DeleteOutlineIcon,
  Star as StarIcon
} from '@material-ui/icons';
import { CourseDto, Status } from '../../../api/coach.generated';
import { DashboardApi } from '../../../api/coachApi';
import { toast } from '../../../components/Toaster';
import useAppContext from '../../../hooks/useAppContext';

const GreyRadio = withStyles({
  root: {
    color: grey[400],
    '&$checked': {
      color: grey[600]
    }
  },
  checked: {}
})((props: RadioProps) => <Radio color="default" {...props} />);

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600]
    }
  },
  checked: {}
})((props: RadioProps) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    marginLeft: theme.spacing(3),
    alignItems: 'end'
  }
}));

const Actions = ({
  course,
  additonalActions,
  onCourseUpdated,
  onGoalRemoved
}: {
  course: CourseDto;
  additonalActions?: React.ReactNode;
  onCourseUpdated: (course: CourseDto) => void;
  onGoalRemoved(goalId: string): void;
}) => {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [value, setValue] = React.useState(course.status);

  React.useEffect(() => {
    setValue(course.status);
  }, [course.status]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const val = (event.target as HTMLInputElement).value;
    const res = await DashboardApi.transitionCourse({
      courseId: course.id,
      status: val as Status
    });

    if (res?.success) {
      setValue(val as Status);
      const newCourse: CourseDto = {
        ...course,
        status: res.data?.status ?? Status.Todo,
        startedOn: res.data?.startedOn,
        completedOn: res.data?.completedOn
      };
      onCourseUpdated(newCourse);
      if (res.data?.completedOn) showReviews(course.id, true);
    }

    setLoading(false);
  };

  const addToGoals = async (e: React.MouseEvent, course: CourseDto) => {
    e.stopPropagation();
    setLoading(true);
    const res = await DashboardApi.addGoal(course.id);
    setLoading(false);
    if (res) {
      toast({ message: 'Goal added', variant: 'success' });
      const newCourse: CourseDto = {
        ...course,
        goalId: res.id,
        goalCreatedOn: res.createdOn
      };
      onCourseUpdated(newCourse);
    } else toast({ message: 'Oops', variant: 'error' });
  };

  const removeGoal = async (e: React.MouseEvent, goalId: string) => {
    e.stopPropagation();
    setLoading(true);
    const res = await DashboardApi.removeGoal(goalId);
    setLoading(false);
    if (res) {
      toast({ message: 'Goal removed', variant: 'success' });
      onGoalRemoved(goalId);
    } else toast({ message: 'Oops', variant: 'error' });
  };

  const formControlClickHandler = (e: React.MouseEvent<HTMLLabelElement>) => {
    e.stopPropagation();
  };

  const { showReviews } = useAppContext();

  return (
    <div className={classes.wrapper}>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="position"
          name="position"
          value={value ?? Status.Todo}
          onChange={handleChange}
          row
        >
          <FormControlLabel
            value={Status.Todo}
            control={<GreyRadio />}
            label="Todo"
            labelPlacement="start"
            disabled={loading}
            onClick={formControlClickHandler}
          />
          <FormControlLabel
            value={Status.InProgress}
            control={<Radio color="primary" />}
            label="In progress"
            labelPlacement="start"
            disabled={loading}
            onClick={formControlClickHandler}
          />
          <FormControlLabel
            value={Status.Done}
            control={<GreenRadio />}
            label="Done"
            labelPlacement="start"
            disabled={loading}
            onClick={formControlClickHandler}
          />
        </RadioGroup>
      </FormControl>
      {course.completedOn && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            showReviews(course.id, true);
          }}
          size="small"
          variant="text"
          className={classes.button}
          endIcon={<StarIcon style={{ color: yellow[800] }} />}
        >
          Leave a review
        </Button>
      )}

      {!course.goalId && !course.completedOn && (
        <Button
          disabled={loading}
          onClick={(e) => addToGoals(e, course)}
          variant="text"
          color="default"
          className={classes.button}
          endIcon={<PlaylistAddIcon />}
          size="small"
        >
          Add goal
        </Button>
      )}
      {course.goalId && (
        <Button
          disabled={loading}
          onClick={(e) => removeGoal(e, course.goalId!)}
          variant="text"
          color="default"
          className={classes.button}
          endIcon={<DeleteOutlineIcon />}
          size="small"
        >
          Remove goal
        </Button>
      )}
      {additonalActions}
    </div>
  );
};

export default Actions;
