import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Route, useLocation } from 'react-router-dom';
import { Paper, Toolbar, Typography, Tooltip, Fab, Box } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { Rights, UserStatsByPathDto } from '../../api/coach.generated';
import UsersTable from './UsersTable';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import Add from './Add';
import Edit from './Edit';
import { UsersApi } from '../../api/coachApi';
import SearchTextField from '../../components/SearchTextField';
import useAppContext from '../../hooks/useAppContext';

const useStyles = makeStyles((theme) => ({
  root: { margin: theme.spacing(2) },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    flex: '1 1 100%'
  },
  actions: { visibility: 'hidden' }
}));

const coursesByPathAndUserEndpoint = (pathId: string, userId: string) =>
  UsersApi.coursesByPathAndUser(pathId, userId);

const userGoalsEndpoint = (userId: string) => UsersApi.goals(userId);

const UserList = () => {
  const classes = useStyles();
  const { accessList } = useAppContext();

  const [userStats, setUserStats] = React.useState<UserStatsByPathDto[]>();
  const [filteredUserStats, setFilteredUserStats] = React.useState<
    UserStatsByPathDto[]
  >();
  const { pathname } = useLocation();

  const filterUsers = (val: string) => {
    if (!val?.trim()) {
      setFilteredUserStats(userStats);
      return;
    }

    const sanitised = val.trim().toLowerCase();
    setFilteredUserStats(
      userStats?.filter(
        (u) =>
          u.firstName?.toLowerCase().startsWith(sanitised) ||
          u.lastName?.toLowerCase().startsWith(sanitised) ||
          u.email?.toLowerCase().startsWith(sanitised)
      )
    );
  };

  const fetchUserList = async () => {
    const profiles: UserStatsByPathDto[] | null = await UsersApi.getAll();
    if (profiles) {
      setUserStats(profiles);
      setFilteredUserStats(profiles);
    }
  };

  const removeProfile = (userId: string): void => {
    const newSet = userStats?.filter((p) => p.userId !== userId);
    setUserStats(newSet);
    setFilteredUserStats(newSet);
  };

  React.useEffect(() => {
    pathname === '/admin/users' && fetchUserList();
  }, [pathname]);

  const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));

  return (
    <Paper className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.title}>
          <Typography display="inline" variant="h6">
            Users
          </Typography>
          <Box display="inline-block" ml={3}>
            <SearchTextField callback={filterUsers} />
          </Box>
        </div>
        {accessList?.users === Rights.Write && (
          <Tooltip title="Add a user">
            <Fab
              color="primary"
              aria-label="add"
              size="small"
              component={Link}
              to={'/admin/users/add'}
              // onClick={() => setFormOpen(true)}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        )}
      </Toolbar>
      <UsersTable
        userStats={filteredUserStats}
        onRemove={removeProfile}
        coursesByPathAndUserEndpoint={coursesByPathAndUserEndpoint}
        userGoalsEndpoint={userGoalsEndpoint}
        canEdit={accessList?.users === Rights.Write}
      />

      <Route path="/admin/users/add" component={Add} />
      <Route path="/admin/users/edit/:userId" component={Edit} />
    </Paper>
  );
};

export default UserList;
