import React from 'react';
import clsx from 'clsx';
import { TableRow, TableCell, Button } from '@material-ui/core';
import {
  PlaylistAdd as PlaylistAddIcon,
  DeleteOutline as DeleteOutlineIcon
} from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CourseDto, Status } from '../../../api/coach.generated';
import moment from 'moment';
import { green } from '@material-ui/core/colors';
import CourseDetails from '../../../components/CourseDetails';
import { UsersApi } from '../../../api/coachApi';
import { toast } from '../../../components/Toaster';
import DueDate from '../../../components/Goal/DueDate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    done: {
      backgroundColor: green[100]
    }
  })
);

const Row = ({
  course,
  user
}: {
  course: CourseDto;
  user: { id: string; name: string };
}) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [internalCourse, setInternalCourse] = React.useState<CourseDto>(course);

  const addToGoals = async (e: React.MouseEvent, course: CourseDto) => {
    e.stopPropagation();
    setLoading(true);
    const goalId = await UsersApi.addGoal(user.id, course.id);
    setLoading(false);
    if (!goalId) return;
    setInternalCourse({ ...internalCourse, goalId });
    toast({ variant: 'success', message: 'Goald addded' });
  };

  const removeGoal = async (e: React.MouseEvent, goalId: string) => {
    e.stopPropagation();
    setLoading(true);
    await UsersApi.removeGoal(user.id, goalId);
    setLoading(false);
    setInternalCourse({ ...internalCourse, goalId: undefined });
    toast({ variant: 'success', message: 'Goald removed' });
  };

  return (
    <TableRow>
      <TableCell style={{ wordBreak: 'break-all' }}>
        <CourseDetails course={course} />
        <br />
        <a href={internalCourse.url} target="_blank" rel="noopener noreferrer">
          {internalCourse.url}
        </a>
      </TableCell>
      <TableCell>
        {internalCourse.startedOn && moment(internalCourse.startedOn).format('l')}
      </TableCell>
      <TableCell>
        {internalCourse.completedOn && moment(internalCourse.completedOn).format('l')}
      </TableCell>
      <TableCell className={clsx({ [classes.done]: internalCourse.status === 'Done' })}>
        {internalCourse.status ?? Status.Todo}
      </TableCell>
      <TableCell>
        {!internalCourse.goalId && !internalCourse.completedOn && (
          <Button
            disabled={loading}
            onClick={(e) => addToGoals(e, internalCourse)}
            variant="text"
            color="default"
            endIcon={<PlaylistAddIcon />}
            size="small"
          >
            Add goal
          </Button>
        )}
        {internalCourse.goalId && (
          <>
            <Button
              disabled={loading}
              onClick={(e) => removeGoal(e, internalCourse.goalId!)}
              variant="text"
              color="default"
              endIcon={<DeleteOutlineIcon />}
              size="small"
            >
              Remove goal
            </Button>
            <DueDate goalId={internalCourse.goalId} dueDate={internalCourse.goalDueDate} />
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;
