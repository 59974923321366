import React from 'react';
import { PathsApi } from '../../api/coachApi';
import ButtonDelete from '../../components/ButtonDelete';

const Delete = ({ pathId, onDeleted }: { pathId: string; onDeleted?(): void }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const removePath = async () => {
    if (!window.confirm('Sure?')) return;
    setIsLoading(true);
    await PathsApi.delete({ id: pathId });
    onDeleted && onDeleted();
    setIsLoading(false);
  };

  return <ButtonDelete isLoading={isLoading} onClick={removePath} />;
};

export default Delete;
