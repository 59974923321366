import React from 'react';
import Skill from './Skill';
import useDashboardContext from '../useDashboardContext';

const Skills = () => {
  const { selectedPath, skills } = useDashboardContext();
  
  return (
    <div style={{ paddingBottom: 20 }}>
      {selectedPath &&
        skills.map((skill) => <Skill key={skill.id} skill={skill} pathId={selectedPath.pathId} />)}
    </div>
  );
};

export default Skills;
