import * as React from 'react';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { ApiResponse } from '../../api/coach.generated';
import { PathsApi } from '../../api/coachApi';
import { toast } from '../../components/Toaster';

const RemoveCourse = ({
  pathId,
  courseId,
  onRemoved
}: {
  pathId: string;
  courseId: string;
  onRemoved: () => void;
}) => {
  const [deleteInProgress, setDeleteInProgress] = React.useState(false);

  const handleRemove = async () => {
    if (!window.confirm('Are you sure?')) return false;

    let response: ApiResponse;
    setDeleteInProgress(true);
    response = (await PathsApi.removeCourse(pathId, courseId))!;
    setDeleteInProgress(false);
    if (response) {
      toast({ message: 'Course removed', variant: 'success' });
      onRemoved();
    } else toast({ message: 'Oops', variant: 'error' });
  };

  return (
    <Button
      disabled={deleteInProgress}
      onClick={() => handleRemove()}
      startIcon={<DeleteOutlineIcon />}
    >
      Delete
    </Button>
  );
};

export default RemoveCourse;
