import React from 'react';
import PathForm from './Form';
import { useHistory } from 'react-router-dom';
import { PathsApi } from '../../../api/coachApi';
import {
  ApiResponse,
  ApiResponseOfPath,
  AddPathRequest
} from '../../../api/coach.generated';
import { toaster } from '../../../components/Toaster';
import { CoachDialogWithTitle } from '../../../components/CoachDialog';
import usePathContext from '../usePathContext';
import useAppContext from '../../../hooks/useAppContext';

const AddPath = () => {
  const history = useHistory();
  const { updateProfile } = useAppContext();
  const { updatePaths } = usePathContext();

  const success = (response: ApiResponseOfPath) => {
    updatePaths();
    updateProfile();
    history.push(`/admin/paths/${response.data!.slug}`);
    toaster.success('Path added');
  };

  const close = () => {
    history.push('/admin/paths');
  };

  const endpoint = (model: AddPathRequest): Promise<ApiResponse | null> =>
    PathsApi.post(model);

  return (
    <CoachDialogWithTitle title="Add path" open onClose={close} loading={false}>
      {<PathForm onSuccess={success} endpoint={endpoint} />}
    </CoachDialogWithTitle>
  );
};

export default AddPath;
