import 'date-fns';
import React from 'react';
import { Box } from '@material-ui/core';
import { GoalDto, CourseDto } from '../../../api/coach.generated';
import Course from '../Pathways/Course';
import Actions from '../Pathways/Actions';
import useDashboardContext from '../useDashboardContext';
import DueDate from '../../../components/Goal/DueDate';

const Goal = ({ goal }: { goal: GoalDto }) => {
  const { refreshGoals, refreshPaths } = useDashboardContext();

  const onCourseUpdated = (course: CourseDto) => {
    refreshPaths();
    refreshGoals();
  };
  const onGoalRemoved = () => {
    refreshGoals();
  };

  return (
    <Box key={goal.id} display="flex" my={2}>
      <Box mr={1}>
        <DueDate goalId={goal.id} dueDate={goal.dueDate} />
      </Box>
      <Box style={{ marginTop: 3 }}>
        <Course
          course={goal.course}
          actions={
            <Actions
              course={goal.course}
              onCourseUpdated={onCourseUpdated}
              onGoalRemoved={onGoalRemoved}
            />
          }
        />
      </Box>
    </Box>
  );
};

export default Goal;
