import {
  UsersClient,
  RolesClient,
  AuthClient,
  PathsClient,
  DashboardClient,
  LoggerClient,
  AccountClient,
  CoursesClient,
  SkillsClient,
  CourseReviewsClient
} from './coach.generated';
import { httpMiddleware } from './middleware';

export const DashboardApi = new DashboardClient(undefined, httpMiddleware);
export const CourseReviewsApi = new CourseReviewsClient(undefined, httpMiddleware);
export const AccountApi = new AccountClient(undefined, httpMiddleware);
export const UsersApi = new UsersClient(undefined, httpMiddleware);
export const RolesApi = new RolesClient(undefined, httpMiddleware);
export const AuthApi = new AuthClient(undefined, httpMiddleware);
export const PathsApi = new PathsClient(undefined, httpMiddleware);
export const CoursesApi = new CoursesClient(undefined, httpMiddleware);
export const SkillsApi = new SkillsClient(undefined, httpMiddleware);
export const LoggerApi = new LoggerClient(undefined, httpMiddleware);
