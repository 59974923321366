import React from 'react';
import { useParams } from 'react-router-dom';
import { GoalDto, SkillStats, UserPathStats } from '../../api/coach.generated';
import { DashboardApi } from '../../api/coachApi';

export type DashboardContextType = {
  selectedPath?: UserPathStats;
  paths: UserPathStats[];
  pathsLoading: boolean;
  goals: GoalDto[];
  goalsLoading: boolean;
  skills: SkillStats[];
  refreshPaths(): void;
  refreshGoals(): void;
  refreshSkills(): void;
};

export const DashboardContext = React.createContext<DashboardContextType>(null!);

const DashboardProvider: React.FC = ({ children }) => {
  const { pathSlug } = useParams<{ pathSlug: string }>();
  const [selectedPath, setSelectedPath] = React.useState<UserPathStats>();
  const [paths, setPaths] = React.useState<UserPathStats[]>([]);
  const [pathsLoading, setPathsLoading] = React.useState<boolean>(false);
  const [goals, setGoals] = React.useState<GoalDto[]>([]);
  const [goalsLoading, setGoalsLoading] = React.useState<boolean>(false);
  const [skills, setSkills] = React.useState<SkillStats[]>([]);

  React.useEffect(() => {
    setSelectedPath(paths?.find((p) => p.pathSlug === pathSlug));
  }, [paths, pathSlug]);

  const refreshPaths = React.useCallback(async () => {
    const pathsRes = await DashboardApi.userPaths();
    pathsRes && setPaths(pathsRes);
  }, []);

  const refreshGoals = React.useCallback(async () => {
    const goalsRes = await DashboardApi.goals();
    goalsRes && setGoals(goalsRes);
  }, []);

  const refreshSkills = React.useCallback(async () => {
    if (!selectedPath) return;
    const categoriesRes = await DashboardApi.skills(selectedPath.pathId);
    categoriesRes && setSkills(categoriesRes);
  }, [selectedPath]);

  React.useEffect(() => {
    const fetchPaths = async () => {
      setPathsLoading(true);
      await refreshPaths();
      setPathsLoading(false);
    };
    fetchPaths();
  }, [refreshPaths, pathSlug]);

  React.useEffect(() => {
    const fetchGoals = async () => {
      setGoalsLoading(true);
      await refreshGoals();
      setGoalsLoading(false);
    };
    fetchGoals();
  }, [refreshGoals]);

  React.useEffect(() => {
    refreshSkills();
  }, [refreshSkills]);

  return (
    <DashboardContext.Provider
      value={{
        selectedPath,
        paths,
        pathsLoading,
        goals,
        goalsLoading,
        skills,
        refreshPaths,
        refreshGoals,
        refreshSkills
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
