import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
  FormControlLabel,
  Checkbox,
  Typography,
  FormHelperText
} from '@material-ui/core';
import { camelToCapitalized } from '../helpers/camelToCapitalized';
import { SelectListItem } from '../api/coach.generated';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120
  },
  fieldGroup: {
    marginTop: theme.spacing(2)
  },
  groupTitle: {
    fontWeight: 'bold'
  },
  genericError: {
    fontSize: '14px',
    marginBottom: '10px'
  }
}));

export type InputTypes = 'password' | 'checkbox' | 'text' | 'checklist' | 'dropdown';

export type Input = {
  name: string;
  value: unknown;
  type: InputTypes;
  checked: boolean;
};

export type FormFieldProps = {
  propName: string;
  value: unknown;
  onChange(input: Input): void;
  //onChange(event: Input): void;
  error?: string;
  label?: string;
  type?: InputTypes;
  className?: string;
  style?: React.CSSProperties;
  required?: boolean;
  disabled?: boolean;
  model: any;
  list?: SelectListItem[];
};

export function GenericError(errorMessage: string): JSX.Element {
  const classes = useStyles();
  return (
    <>
      {errorMessage && (
        <FormHelperText error className={classes.genericError}>
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
}

export function FormField({
  propName,
  value,
  onChange,
  error,
  label,
  type,
  className,
  style,
  required,
  disabled,
  model,
  list
}: FormFieldProps) {
  const classes = useStyles();

  const onChangeHelper = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    name: string,
    type: InputTypes,
    checked: boolean = false
  ) => {
    event.persist();
    return onChange({ type, name, value: event.target.value, checked });
  };

  const checkbox = () => (
    <FormControlLabel
      className={className || classes.fieldGroup}
      control={
        <Checkbox
          checked={!!value || false}
          color="primary"
          name={propName}
          onChange={(e) => onChangeHelper(e, propName, 'checkbox', e.target.checked)}
        />
      }
      label={
        <Typography color="textSecondary" variant="body1">
          {label}
        </Typography>
      }
    />
  );

  const textField = () => (
    <TextField
      fullWidth
      error={!!error}
      helperText={error}
      style={style}
      label={label || camelToCapitalized(propName)}
      name={propName!}
      onChange={(e) => onChangeHelper(e, propName, 'text')}
      className={className || classes.fieldGroup}
      type={type || 'text'}
      value={value}
      variant="outlined"
      autoComplete="new-password"
      required={required}
      disabled={disabled}
    />
  );

  const dropdown = () => {
    const onChangeHelper = (event: React.ChangeEvent<{}>, val: SelectListItem | null) => {
      event.persist();
      return onChange({
        name: propName,
        value: val?.id,
        type: 'dropdown',
        checked: false
      });
    };

    return (
      <Autocomplete
        options={list ?? []}
        value={list?.find((e) => e.id === value) ?? null}
        getOptionLabel={(option) => option.label}
        onChange={onChangeHelper}
        multiple={undefined}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            error={!!error}
            helperText={error}
            style={style}
            label={label || camelToCapitalized(propName)}
            name={propName}
            className={className || classes.fieldGroup}
            required={required}
            disabled={disabled}
            variant="outlined"
          />
        )}
      />
    );
  };

  const checkList = () => {
    const prop = model[propName] as SelectListItem[];
    const computedPropName = (item: SelectListItem) => `${propName}__${item.id}`;
    return (
      <div className={classes.fieldGroup}>
        <Typography className={classes.groupTitle}>{label}</Typography>
        {prop &&
          prop.map((item) => (
            <FormControlLabel
              key={item.id}
              control={
                <Checkbox
                  checked={item.selected}
                  color="primary"
                  name={computedPropName(item)}
                  onChange={(e) =>
                    onChangeHelper(
                      e,
                      computedPropName(item),
                      'checklist',
                      e.target.checked
                    )
                  }
                />
              }
              label={
                <Typography color="textSecondary" variant="body1">
                  {camelToCapitalized(item.label)}
                </Typography>
              }
            />
          ))}
      </div>
    );
  };

  const componentMap: any = {
    checkbox: checkbox,
    default: textField,
    checklist: checkList,
    dropdown: dropdown
  };

  return type && componentMap[type] ? componentMap[type]() : componentMap.default();
}
