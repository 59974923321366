import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Course from './Course';
import { CourseDto } from '../../../api/coach.generated';
import { DashboardApi } from '../../../api/coachApi';
import Actions from './Actions';
import LoadingSkeleton from '../../../components/LoadingSkeleton';
import useDashboardContext from '../useDashboardContext';
import useAppContext from '../../../hooks/useAppContext';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 20
  }
}));

export default function Courses({
  pathId,
  skillId,
  count
}: {
  pathId: string;
  skillId: string;
  count: number;
}) {
  const { subscribeOnCourseUpdate, unSubscribeOnCourseUpdate } = useAppContext();
  const { goals, refreshPaths, refreshGoals, refreshSkills } = useDashboardContext();
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(true);
  const [courses, setCourses] = React.useState<CourseDto[]>([]);

  const fetchCourses = React.useCallback(async () => {
    const coursesRes = await DashboardApi.courses(pathId, skillId);
    coursesRes && setCourses(coursesRes);
  }, [pathId, skillId]);

  React.useEffect(() => {
    const fn = (courseId: string) => {
      if (courses.find((c) => c.id === courseId)) fetchCourses();
    };
    subscribeOnCourseUpdate(fn);
    return () => {
      unSubscribeOnCourseUpdate(fn);
    };
  }, [courses, fetchCourses, subscribeOnCourseUpdate, unSubscribeOnCourseUpdate]);

  React.useEffect(() => {
    const fetch = async () => {
      await fetchCourses();
      setIsLoading(false);
    };
    fetch();
  }, [fetchCourses, goals]);

  const onCourseUpdated = (course: CourseDto) => {
    fetchCourses();
    refreshPaths();
    refreshGoals();
    refreshSkills();
  };
  const onGoalRemoved = () => {
    refreshGoals();
  };

  return (
    <div className={classes.root}>
      <div>
        {isLoading && <LoadingSkeleton variant="list" rows={count} />}
        {!isLoading && !courses.length && <span>No courses</span>}
        {courses.map((course) => (
          <Box key={course.id} mb={2}>
            <Course
              course={course}
              hideSkillAndRank
              actions={
                <Actions
                  course={course}
                  onCourseUpdated={onCourseUpdated}
                  onGoalRemoved={onGoalRemoved}
                />
              }
            />
          </Box>
        ))}
      </div>
    </div>
  );
}
