import React from 'react';
import DashboardProvider from './DashboardProvider';
import Dashboard from './Dashboard';

const index = () => {
  return (
    <DashboardProvider>
      <Dashboard />
    </DashboardProvider>
  );
};

export default index;
